import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    Button, Form, notification, Typography, Spin, Row, Col, Breadcrumb, Divider
} from 'antd';
import {
    SolutionOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {
    cardLayout,
    botaoSalvar,
} from './GridStyle';
import { useHistory } from 'react-router-dom';
import './styles.css';
import FormCriarContrato from './../../../../components/FormCriarContrato';
import axios from 'axios';
import { urlContrato } from '../../../../services/request/urls';
import { convertMoedaBRParaUS, convertDataBrParaUS, convertDataUSParaBR, convertMoedaUSParaBRInicio } from './../../../../services/Conversores';

import { getToken } from '../../../../services/token';

const { Title } = Typography;

interface dadosDaTabelaAssoc {
    id: string;
    nome: string;
    estado: string;
    isAssoc: boolean;
};

interface dadosDaTabela {
    id: number;
    nome: string;
    estado: string;
    isAssoc?: boolean;
};

interface dadosDaTabelaCityMicro {
    id: number;
    nome: string;
    microregiao: Array<any>;
    isAssoc?: boolean;
};

interface interfDadosTabela {
    id: number;
    uuid: string;
    nome: string;
    status: boolean;
    envio_proposta: string;
    vigencia: string;
    valor_contratado: string;
    cadastro_processos: string;
    observacao: string;
    created_at: string;
    updated_at: string;
    modalidade: {
        id: number;
        nome: string;
    },
    esfera: {
        id: number;
        nome: string;
    },
    poder: {
        id: number;
        nome: string;
    },
    situacao_contrato: {
        id: number;
        nome: string;
    },
    tipo_contrato: {
        id: number;
        nome: string;
    },
    controladorContrato: [
        {
            id: number | null;
            contratoId: number | null;
            cidadeId: number | null;
            estadoId: number | null;
            usuarioId: number | null;
            permissaoNotificacaoId: number | null;
        }
    ]
}

const CreateContrato: React.FC = () => {
    const formRef = useRef<any>(null);
    const history = useHistory();

    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [propsDataTableState, setPropsDataTableState] = useState<Array<dadosDaTabela | dadosDaTabelaAssoc | dadosDaTabelaCityMicro>>([]);

    const [isSalvarOrEditar, setIsSalvarOrEditar] = useState(true);

    const comonentDidMount = useEffect((): void => {
        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:contrato');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            formRef.current.setFieldsValue(
                {
                    nome: localUserData?.nome ? localUserData.nome : undefined,
                    status: localUserData?.status,
                    validade_teste: localUserData?.validade_teste ? convertDataUSParaBR(localUserData.validade_teste) : undefined,
                    envio_proposta: localUserData?.envio_proposta ? convertDataUSParaBR(localUserData.envio_proposta) : undefined,
                    vigencia: localUserData?.vigencia ? convertDataUSParaBR(localUserData.vigencia) : undefined,
                    vigencia_atual: localUserData?.vigencia_atual ? convertDataUSParaBR(localUserData.vigencia_atual) : undefined,
                    valor_contratado: localUserData?.valor_contratado ? convertMoedaUSParaBRInicio(localUserData.valor_contratado) : undefined,
                    cadastro_processos: localUserData?.cadastro_processos ? convertDataUSParaBR(localUserData.cadastro_processos) : undefined,
                    observacao: localUserData?.observacao ? localUserData.observacao : undefined,
                    modalidade: localUserData?.modalidade?.id ? localUserData.modalidade.id : undefined,
                    esfera: localUserData?.esfera.id ? localUserData.esfera.id : undefined,
                    poder: localUserData?.poder.id ? localUserData.poder.id : undefined,
                    situacao_contrato: localUserData?.situacao_contrato?.id ? localUserData.situacao_contrato.id : undefined,
                    tipo_contrato: localUserData?.tipo_contrato?.id ? localUserData.tipo_contrato.id : undefined,
                    data_criado: localUserData?.created_at ? moment(localUserData.created_at).format('DD/MM/YYYY HH:mm') : undefined,
                    planoUuid: localUserData?.plano?.uuid ? localUserData.plano.uuid : undefined,
                }
            );
            setIsSalvarOrEditar(false);

            if (localUserData?.vendedores && localUserData?.vendedores.length !== 0) {

                let vendedoresID: any = []
                localUserData?.vendedores.forEach((vende: any) => {
                    vendedoresID.push(vende.id)
                });
                formRef.current.setFieldsValue(
                    {
                        vendedores: vendedoresID.length !== 0 ? vendedoresID : undefined,
                    }
                )
            }
            if (localUserData?.estados && localUserData?.estados.length !== 0) {

                let modoEstadoID: any = []
                localUserData?.estados.forEach((estados: any) => {
                    modoEstadoID.push(estados.id)
                });
                formRef.current.setFieldsValue(
                    {
                        estados: modoEstadoID.length !== 0 ? modoEstadoID : undefined,
                    }
                )
            }

            //CIDADES SETANDO NO ARQUIVO FORM cRIAR CONTRATO PARA PODER SETAR NO STATE DA TABELA
        }
    }, []);

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        history.push("/contrato");
    }, []);

    const onClickBotaoSalvar = useCallback((): void => {

        const salvarDadosModal = async (valores: any) => {
            setLoadingFormAdd(true);
            try {

                let objIdVendedores: any = [];

                if (valores.vendedores && valores.vendedores.length != 0) {
                    valores.vendedores.forEach((val: any, i: any) => {
                        objIdVendedores.push({ id: val })
                    });

                    valores.vendedores = objIdVendedores
                }

                let objIDEstados: any = [];

                if (valores.estados && valores.estados.length != 0) {
                    valores.estados.forEach((val: any, i: any) => {
                        objIDEstados.push({ id: val })
                    });

                    valores.estados = objIDEstados
                }

                //PARA PODER FAZER A REQUISIÇAO APENAS COM ID
                let cityEditID: any = []
                valores.cidades.forEach((vende: any) => {
                    cityEditID.push({ id: vende.id })
                });

                valores.cidades = cityEditID

                let resultCidade = await axios.post(urlContrato, valores,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });
                setLoadingFormAdd(false);
                history.push("/contrato");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }
        formRef.current.validateFields()
            .then((values: any) => {
                if (formRef.current.getFieldValue('estados') != undefined &&
                    formRef.current.getFieldValue('estados').length != 0 ||
                    propsDataTableState.length != 0) {
                    //seta dados da tabela em obj da requisição
                    values.cidades = propsDataTableState

                    //converte dinheiro
                    let valorContratado = convertMoedaBRParaUS(values.valor_contratado)
                    values.valor_contratado = valorContratado ? valorContratado : null;

                    //para formatar data em formato americano :D
                    let envioPropsota = convertDataBrParaUS(values.envio_proposta);
                    values.envio_proposta = envioPropsota ? envioPropsota : null;

                    let vigencia = convertDataBrParaUS(values.vigencia);
                    values.vigencia = vigencia ? vigencia : null;

                    let validadeTeste = convertDataBrParaUS(values.validade_teste);
                    values.validade_teste = validadeTeste ? validadeTeste : null;

                    values.situacao_contrato = values?.situacao_contrato ? { id: values?.situacao_contrato } : null
                    values.esfera = values?.esfera ? { id: values?.esfera } : null
                    values.poder = values?.poder ? { id: values?.poder } : null
                    values.tipo_contrato = values?.tipo_contrato ? { id: values?.tipo_contrato } : null
                    values.modalidade = values?.modalidade ? { id: values?.modalidade } : null

                    salvarDadosModal(values);
                } else {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Selecione uma cidade ou modo estado!',
                    });
                }

            })
            .catch((errorInfo: any) => {
                // console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [propsDataTableState]);

    const onClickBotaoEditar = useCallback((): void => {

        const EditarDados = async (valores: any) => {
            setLoadingFormAdd(true);
            let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:contrato');
            let localUserData: any = null;
            if (typeof dadosLocalStorage == 'string') {
                localUserData = JSON.parse(dadosLocalStorage);
            }
            try {

                let objIdVendedores: any = [];

                if (valores.vendedores && valores.vendedores.length != 0) {
                    valores.vendedores.forEach((val: any, i: any) => {
                        objIdVendedores.push({ id: val })
                    });

                    valores.vendedores = objIdVendedores
                }

                let objIDEstados: any = [];

                if (valores.estados && valores.estados.length != 0) {
                    valores.estados.forEach((val: any, i: any) => {
                        objIDEstados.push({ id: val })
                    });

                    valores.estados = objIDEstados
                }


                valores.id = localUserData.id

                //PARA PODER FAZER A REQUISIÇAO APENAS COM ID
                let cityEditID: any = []
                valores.cidades.forEach((vende: any) => {
                    cityEditID.push({ id: vende.id })
                });

                valores.cidades = cityEditID

                let resultCidade = await axios.put(urlContrato, valores,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram atualizados com sucesso!',
                });
                setLoadingFormAdd(false);
                history.push("/contrato");
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAdd(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }
        }
        formRef.current.validateFields()
            .then((values: any) => {
                if (formRef.current.getFieldValue('estados') != undefined &&
                    formRef.current.getFieldValue('estados').length != 0 ||
                    propsDataTableState.length != 0) {
                    //seta dados da tabela em obj da requisição
                    values.cidades = propsDataTableState

                    //converte dinheiro
                    let valorContratado = convertMoedaBRParaUS(values.valor_contratado)
                    values.valor_contratado = valorContratado ? valorContratado : null;

                    //para formatar data em formato americano :D
                    let envioPropsota = convertDataBrParaUS(values.envio_proposta);
                    values.envio_proposta = envioPropsota ? envioPropsota : null;

                    let vigencia = convertDataBrParaUS(values.vigencia);
                    values.vigencia = vigencia ? vigencia : null;

                    let validadeTeste = convertDataBrParaUS(values.validade_teste);
                    values.validade_teste = validadeTeste ? validadeTeste : null;

                    values.situacao_contrato = values?.situacao_contrato ? { id: values?.situacao_contrato } : null
                    values.esfera = values?.esfera ? { id: values?.esfera } : null
                    values.poder = values?.poder ? { id: values?.poder } : null
                    values.tipo_contrato = values?.tipo_contrato ? { id: values?.tipo_contrato } : null
                    values.modalidade = values?.modalidade ? { id: values?.modalidade } : null

                    EditarDados(values);
                } else {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Selecione uma cidade ou modo estado!',
                    });
                }

            })
            .catch((errorInfo: any) => {
                // console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [propsDataTableState]);

    //funcao injetada no formulario para poder pegar o valor da tabela dos itens
    const funcSetValorTabela = useCallback((valor: Array<dadosDaTabela | dadosDaTabelaAssoc | dadosDaTabelaCityMicro>): void => {
        setPropsDataTableState(valor)
    }, []);

    return (
        <>
            <Spin spinning={loadingFormAdd}>
                <Row>
                    <Col {...cardLayout}>
                        <Title level={3}><SolutionOutlined style={{ fontSize: 35, marginRight: 10 }} /> {isSalvarOrEditar ? "Adicionar" : "Editar"} Contrato</Title>
                        <Breadcrumb>
                            <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Contratos</a></Breadcrumb.Item>
                            <Breadcrumb.Item>{isSalvarOrEditar ? "Adicionar" : "Editar"} Contrato</Breadcrumb.Item>
                        </Breadcrumb>
                        <Divider />
                    </Col>
                </Row>
                <Form
                    ref={formRef}
                    name="formNewUserStep2"
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                >
                    <FormCriarContrato formRef={formRef} funcSetValorTabela={funcSetValorTabela} />
                </Form>
                <Row>
                    <Divider />
                    <Col {...botaoSalvar}>
                        <Button style={{ marginRight: 10 }} onClick={onClickVoltarConfigOfSystem} >
                            Cancelar
                        </Button>
                        {isSalvarOrEditar ?
                            <Button type="primary" onClick={onClickBotaoSalvar} >
                                Salvar
                            </Button>
                            :
                            <Button type="primary" onClick={onClickBotaoEditar} >
                                Atualizar
                            </Button>
                        }
                    </Col>
                </Row>
            </Spin>
        </>
    );
}

export default CreateContrato;
