import React, { useState, useCallback, useEffect, useRef, ReactNode } from 'react';
import {
    Table, Input, Button, Space, notification, Typography, Row, Col,
    Select, Modal, Divider, Tag, Spin, Form, Alert, Radio, Switch
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
// import locale from 'antd/es/date-picker/locale/pt_BR';
import { DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker, DateTimePicker } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { format, addDays } from 'date-fns'
import axios from 'axios';
import {
    urlPoder,
    urlBuscaCidades,
    urlCargo,
    urlUserContratoAutomatico,
    urlBuscaUserByCelular,
    urlCidadesContrato,
    urlContratoAtivos,
    urlUsersAutomaticoAtendimento
} from './../../../../services/request/urls';

import { getToken } from './../../../../services/token';
import Icon from '@ant-design/icons';
import { mask, unMask } from '../../../../utils/MascaraDeCampos';

import {
    modalAddModuloInputs,
} from './GridStyle';
import {
    convertDataBrParaUS, convertDataEHoraBrParaUS
} from './../../../../services/Conversores';

import {
    removeAcento
} from './../../../../utils/RemoveAcentos';

import { FaSearch } from 'react-icons/fa';

const { Text, Title } = Typography;

const IconSerach = (props: any) => <Icon {...props} component={FaSearch} ></Icon>

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}


interface interfDadosDoContrato {
    id: number;
    uuid: string;
    nome: string;
    status: boolean;
    envio_proposta: string;
    vigencia: string | number | null | Date | boolean | any;
    valor_contratado: string;
    cadastro_processos: string;
    observacao: string;
    created_at: string;
    updated_at: string;
    modalidade: {
        id: number;
        nome: string;
    },
    esfera: {
        id: number;
        nome: string;
    },
    poder: {
        id: number;
        nome: string;
    },
    situacao_contrato: {
        id: number;
        nome: string;
    },
    tipo_contrato: {
        id: number;
        nome: string;
    },
    controladorContrato: [
        {
            id: number | null;
            contratoId: number | null;
            cidadeId: number | null;
            estadoId: number | null;
            usuarioId: number | null;
            permissaoNotificacaoId: number | null;
        }
    ]
}

interface DadosCidadesAll {
    "id": number,
    "uuid": string,
    "nome": string,
    "microrregiao": {
        "mesorregiao": {
            "estado": {
                "id": number,
                "uuid": string,
                "sigla": string,
                "nome": string
            }
        }
    }
}

interface interfaceConfigs {
    nome: string,
    id: number
}

interface intfaDadosCidadesDoContrato {
    estadoId: number,
    estadoNome: string,
    estadoSigla: string,
    ibge_id_antigo: number | null,
    id: number,
    latitude: string | null,
    longitude: string | null,
    microrregiaoId: number | null,
    nome: string | null,
    populacao: number | null,
    uf: string | null,
    uuid: string,
    usuarios: string,
}

interface PropsModal {
    visible: boolean;
    setFecharModal: () => void;
}

const ModalUsuarioRapido: React.FC<PropsModal> = ({ visible, setFecharModal }) => {

    // const formRef = useRef<any>(null);
    // formRef = React.createRef();

    const refAllDadosContrato = useRef<Array<interfDadosDoContrato>>([]);

    const queryParans = useQuery();

    //para poder cancelar a requisição
    const signal = axios.CancelToken.source();
    const signal1 = axios.CancelToken.source();

    const [selectedDate, handleDateChange] = useState<any>(null);
    const [selectedDateVincularUserRapido, setSelectedDateVincularUserRapido] = useState<any>(null);

    const [loadingTable, setLoadingTable] = useState(false);

    //user rapido states
    const refFormModalUserRapido = React.useRef<any>(null);
    const inputNomeCompletoUserRapido = React.useRef<any>(null);
    const refIdPoderModalUserRapido = React.useRef<any>(null);
    const refNomeCidadeModalUserRapido = React.useRef<any>(null);

    const valorLsStorageContratoSelecionado = React.useRef<null | { nome: string, id: number }>(null);

    const [loadingFormAddUserRapido, setLoadingFormAddUserRapido] = useState(false);
    const [modalAddUserRapido, setModalAddUserRapido] = useState(false);
    const [loadingPoderSelected, setLoadingPoderSelected] = useState(false);

    const [dadosPoderList, setDadosPoderList] = useState<Array<interfaceConfigs>>([]);
    const refDadosPoderList = React.useRef<Array<interfaceConfigs>>([]);

    const [loadingSelectedCidades, setLoadingSelectedCidades] = useState(false);
    const [dadosCidadesList, setDadosCidadesList] = useState<Array<DadosCidadesAll>>([]);

    const [dadosDoCargoList, setDadosDoCargoList] = useState<Array<interfaceConfigs>>([]);
    const refDadosCargoList = React.useRef<Array<interfaceConfigs>>([]);

    const [isVisibleDateProximoContato, setIsVisibleDateProximoContato] = useState(false);


    const [stateContratoSituacao, setStateContratoSituacao] = useState<{ nome: string, id: number } | null>(null);
    const [valorRadioButtomUsuarioRapido, setValorRadioButtomUsuarioRapido] = useState<'SemContrato' | 'ComContrato'>('ComContrato');
    const [dataListContrato, setDataListContrato] = useState<Array<interfDadosDoContrato>>([]);
    const [cidadesDoContratoInSelect, setCidadesDoContratoInSelect] = useState<Array<intfaDadosCidadesDoContrato>>([]);

    // const [, setValorLsStorageContratoSelecionado] = useState<>(null);

    const buscaDadosContratosAndSetSelectedUserRapido = useCallback(() => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingTable(true)
                let result = await axios.get(urlContratoAtivos,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() }
                    });

                refAllDadosContrato.current = result.data

                setDataListContrato(result.data);
                setLoadingTable(false)

                return true

            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
                return false
            }
        }

        return buscaDeDadosContratos();

    }, []);

    //executa apenas ao iniciar o component
    useEffect(() => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:ListUsersContrato');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        if (localUserData) {
            valorLsStorageContratoSelecionado.current = localUserData
        }

        // buscaDadosContratosAndSetSelectedUserRapido();

    }, []);

    //executa apenas quando desmonta o component
    const comonentExecutaAoDestroy = useEffect(() => () => {
        signal.cancel("Requisicao cancelada!")
        signal1.cancel("Requisicao cancelada!")
    }, []);

    const encontrarCargoPorId = useCallback((id): any => {

        const recebeFilter = refDadosCargoList.current.filter((element, index) => (
            element.id === id
        ))

        return recebeFilter[0]

    }, []);

    const encontrarPoderPorId = useCallback((id): any => {

        const recebeFilter = refDadosPoderList.current.filter((element, index) => (
            element.id === id
        ))

        return recebeFilter[0]

    }, []);

    const encontrarPoderPorNomeESetarCampo = useCallback((nome): void => {

        const recebeFilter = refDadosPoderList.current.filter((element, index) => (
            element.nome === nome
        ))

        refFormModalUserRapido.current?.setFieldsValue({
            poder_id: recebeFilter[0]?.id,
        });

    }, []);

    const encontrarContratoPorId = useCallback((id): void => {

        const recebeFilter = refAllDadosContrato.current.filter((element, index) => (
            element.id === id
        ))

        encontrarPoderPorNomeESetarCampo(recebeFilter[0].poder.nome)

        setStateContratoSituacao(recebeFilter[0]?.tipo_contrato)

    }, []);

    const buscaDadosCidadesPorContrato = useCallback((id: string | number): void => {

        const buscaDeDadosContratos = async () => {
            try {

                setLoadingTable(true)

                let result = await axios.get(urlCidadesContrato + '/' + id,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });

                setCidadesDoContratoInSelect(result.data);

                if (result.data.length === 1) {

                    refFormModalUserRapido.current?.setFieldsValue({
                        cidade_id: result.data[0].id,
                    });
                } else {
                    refFormModalUserRapido.current?.setFieldsValue({
                        cidade_id: undefined,
                    });
                }


                setLoadingTable(false)


            } catch (error) {

                setLoadingTable(false)

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDadosContratos();

    }, []);

    const onChangeUsuarioRapido = useCallback((val): void => {

        setValorRadioButtomUsuarioRapido(val.target.value)

        if (!valorLsStorageContratoSelecionado.current) {
            if (val.target.value === 'ComContrato') {
                refFormModalUserRapido.current?.setFieldsValue({
                    cidade_id: undefined,
                    contrato_id: undefined,
                });
            }
        }

    }, []);

    const onChangeSelectContratos = useCallback((value, obj): void => {

        encontrarContratoPorId(value)

        // refIDContrato.current = value;
        // refNomeContrato.current = obj?.children;

        buscaDadosCidadesPorContrato(value);

    }, []);

    //funcoes user rapido
    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosPoderList = useCallback(() => {

        setLoadingPoderSelected(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlPoder,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosPoderList(result.data);
                refDadosPoderList.current = result.data

                setLoadingPoderSelected(false);
            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingPoderSelected(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDadosPoder();

    }, []);

    const buscarDadosCargosList = useCallback(() => {

        const buscaDadosCargo = async () => {
            try {
                let result = await axios.get(urlCargo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                setDadosDoCargoList(result.data)
                refDadosCargoList.current = result.data

            } catch (error) {
                console.error(error);
            }
        }

        buscaDadosCargo();

    }, []);

    //para setar no campo parametro um e dois, se for setar no campo pelo id param 3 e 4
    const buscarDadosCidadesList = useCallback(() => {

        setLoadingSelectedCidades(true);
        const buscaDadosPoder = async () => {
            try {
                let result = await axios.get(urlBuscaCidades,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        cancelToken: signal.token
                    });
                setDadosCidadesList(result.data)

                setLoadingSelectedCidades(false);

                return true
            } catch (error) {
                setLoadingSelectedCidades(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSelectedCidades(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }

                return false
            }
        }

        return buscaDadosPoder();

    }, []);

    const buscarDadosAoAbrirModalUserRapido = useCallback(() => {

        const buscaDadosEAvririModal = async () => {
            try {
                refFormModalUserRapido.current?.resetFields();

                setModalAddUserRapido(true);
                setLoadingFormAddUserRapido(true)

                buscarDadosPoderList()

                buscarDadosCargosList();

                let dataSomada = format(
                    addDays(
                        new Date(),
                        15
                    )
                    , 'yyyy-MM-dd'
                )

                let dataSomadaHora = format(
                    addDays(
                        new Date(),
                        15
                    )
                    , 'yyyy-MM-dd HH:mm'
                )
                // let dataSomada = addDays(
                //     new Date(),
                //     7
                // )

                if (dadosCidadesList.length > 0) {

                } else {

                    buscarDadosCidadesList();
                }


                setTimeout(() => {

                    // refFormModalUserRapido.current?.setFieldsValue({
                    //     validade_teste: moment(dataSomada),
                    // });

                    // setSelectedDateVincularUserRapido(moment(dataSomadaHora))

                    handleDateChange(moment(dataSomada))

                    if (valorLsStorageContratoSelecionado.current) {
                        refFormModalUserRapido.current?.setFieldsValue({
                            contrato_id: valorLsStorageContratoSelecionado.current?.id,
                        });

                        encontrarContratoPorId(valorLsStorageContratoSelecionado.current?.id)

                        buscaDadosCidadesPorContrato(valorLsStorageContratoSelecionado.current?.id);
                    }
                    // inputNomeCompletoUserRapido.current?.focus();

                }, 500);


                setLoadingFormAddUserRapido(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {
                    setLoadingSelectedCidades(false);
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDadosEAvririModal();

    }, [dadosCidadesList]);

    useEffect(() => {
        const buscaDeDados = async () => {
            try {

                if (visible) {

                    await buscaDadosContratosAndSetSelectedUserRapido();
                    buscarDadosAoAbrirModalUserRapido();
                }
            } catch (error) {

                let msgErro: any = (error as Error);

                if (msgErro?.message != 'Requisicao cancelada!') {

                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar os dados!',
                    });
                }
            }
        }

        buscaDeDados();

    }, [visible]);

    const buscarCelularSeJaExiste = useCallback((celular: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get(urlBuscaUserByCelular + celular,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data == 'string') {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar celular!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);


    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            // "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refFormModalUserRapido.current.setFieldsValue(
            { celular: maskedValue }
        );
        if (maskedValue.length == 16) {
            refFormModalUserRapido.current.validateFields(["celular"])
        }

    }, []);

    const onChangeSwitchIsVisibleDateProximoContato = useCallback((checked): void => {
        let dataEHoraSomada = format(
            addDays(
                new Date(),
                7
            )
            , 'yyyy-MM-dd HH:mm'
        )

        setTimeout(() => {
            setSelectedDateVincularUserRapido(moment(dataEHoraSomada))
        }, 500);

        setIsVisibleDateProximoContato(checked)
    }, []);

    const clickCancelModalUserRapido = useCallback((): void => {
        setModalAddUserRapido(false);
        setFecharModal();
    }, []);

    const clickSubmitModalAddUserRapido = useCallback((): void => {

        const salvarDadosModal = async (values: any) => {
            try {
                setLoadingFormAddUserRapido(true);

                if (valorRadioButtomUsuarioRapido === 'ComContrato') {

                    await axios.post(urlUsersAutomaticoAtendimento, values,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });

                } else {

                    await axios.post(urlUserContratoAutomatico, values,
                        { headers: { 'Authorization': 'Bearer ' + getToken() } });

                }

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram atualizados com sucesso!',
                });

                setLoadingFormAddUserRapido(false);

                // buscaDadosUsuariosAtivosAndSetTable();
                // mensagemDeAtencaoNaTelaPeloCache(true);

                clickCancelModalUserRapido();
                refFormModalUserRapido.current.resetFields();
            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingFormAddUserRapido(false);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Não foi possivel salvar, entre em contato com suporte!',
                });
            }
        }

        // Com contrato é uma rota sem contrato é outra rota,
        // com contrato é a rota de atendimentos, e sem contrato é a rota que ja esta usuario rapido

        refFormModalUserRapido.current?.validateFields()
            .then((values: any) => {

                values = {
                    ...values,
                    celular: unMask(values.celular),
                    // validade_teste: values?.validade_teste ? convertDataBrParaUS(values.validade_teste) : undefined,
                    validade_teste: selectedDate ? convertDataBrParaUS(selectedDate) : undefined,
                    data_proximo_contato: selectedDateVincularUserRapido && selectedDateVincularUserRapido != '' ?
                        convertDataEHoraBrParaUS(selectedDateVincularUserRapido)
                        : undefined,
                }

                const nomePoder = encontrarPoderPorId(values.poder_id)?.nome
                const nomeCargo = encontrarCargoPorId(values.cargo_id)?.nome
                if (
                    nomePoder &&
                    nomePoder === 'Executivo'
                ) {
                    if (nomeCargo &&
                        nomeCargo === 'Vereador(a)') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Vereador no contrato de poder Executivo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModal(values);
                            },
                            onCancel() {
                            },
                        });
                        return false
                    }
                    if (nomeCargo &&
                        nomeCargo === 'Presidente da Câmara') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Presidente da Câmara no contrato de poder Executivo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModal(values);
                            },
                            onCancel() {
                            },
                        });
                        return false
                    }

                }

                if (
                    nomePoder &&
                    nomePoder === 'Legislativo'
                ) {
                    if (nomeCargo &&
                        nomeCargo === 'Prefeito(a)') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModal(values);
                            },
                            onCancel() {
                            },
                        });
                        return false

                    }
                    if (nomeCargo &&
                        nomeCargo === 'Ex-Prefeito(a)') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Ex-Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModal(values);
                            },
                            onCancel() {
                            },
                        });
                        return false
                    }
                    if (nomeCargo &&
                        nomeCargo === 'Vice Prefeito(a)') {
                        Modal.confirm({
                            className: 'modalContratoAtivoInativo',
                            title: 'Cuidado',
                            icon: <ExclamationCircleOutlined />,
                            content: 'O usuário cadastrado é Vice Prefeito(a) no contrato de poder Legislativo! Tem certeza que deseja continuar?',
                            okText: 'Sim',
                            okType: 'danger',
                            cancelText: 'Rever campos',
                            onOk() {
                                salvarDadosModal(values);
                            },
                            onCancel() {
                            },
                        });
                        return false
                    }
                }

                salvarDadosModal(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [selectedDate, selectedDateVincularUserRapido, valorRadioButtomUsuarioRapido]);

    return (
        <>
            <Modal
                title="Adicionar Usuário Rápido"
                visible={modalAddUserRapido}
                className="modalAddAtendimentos"
                onOk={clickSubmitModalAddUserRapido}
                onCancel={clickCancelModalUserRapido}
                footer={[
                    <Button key="back" disabled={loadingFormAddUserRapido} onClick={clickCancelModalUserRapido}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAddUserRapido} onClick={clickSubmitModalAddUserRapido}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAddUserRapido}>
                    <Form
                        ref={refFormModalUserRapido}
                        name="formModalAddContato"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col span={24}>
                                <Radio.Group
                                    style={{
                                        marginBottom: 10,
                                        marginLeft: 5,
                                        marginRight: 5,
                                    }}
                                    buttonStyle="solid"
                                    onChange={onChangeUsuarioRapido}
                                    value={valorRadioButtomUsuarioRapido}
                                >
                                    <Radio.Button value={'ComContrato'}>Com contrato</Radio.Button>
                                    <Radio.Button value={'SemContrato'}>Sem contrato</Radio.Button>
                                </Radio.Group>
                            </Col>
                            {
                                valorRadioButtomUsuarioRapido === 'ComContrato' ?
                                    <>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                name="contrato_id"
                                                label="Contrato"
                                                style={{ margin: 5 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione o contrato!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Selecione um Contrato"
                                                    optionFilterProp="children"
                                                    // className="botaoListVendedorUser"
                                                    onChange={onChangeSelectContratos}
                                                    // disabled={!hasSelectedAdd}
                                                    // defaultValue='Usuários'
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: 5,
                                                        marginRight: 5,
                                                    }}
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    {
                                                        dataListContrato.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {
                                                                        item?.poder?.nome ?
                                                                            item.nome + ' - ' + item?.poder?.nome
                                                                            :
                                                                            item.nome
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                name="cidade_id"
                                                label="Cidade"
                                                style={{ margin: 5 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione a cidade!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    loading={loadingSelectedCidades}
                                                    notFoundContent={loadingSelectedCidades ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    onSelect={(id: any, obj: any) => {

                                                        refNomeCidadeModalUserRapido.current = obj?.children

                                                    }}
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }
                                                    }
                                                >
                                                    {
                                                        cidadesDoContratoInSelect.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome + ' - ' + item?.estadoSigla}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                name="poder_id"
                                                label="Poder"
                                                initialValue={2}
                                                style={{ margin: 5 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione o poder!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    disabled
                                                    loading={loadingPoderSelected}
                                                    notFoundContent={loadingPoderSelected ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    onSelect={(id: any, obj: any) => {

                                                        refIdPoderModalUserRapido.current = id

                                                    }}
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    {dadosPoderList.map((item) => {
                                                        return (
                                                            <Select.Option
                                                                value={item.id}
                                                                key={item.id}
                                                            >
                                                                {item.nome}
                                                            </Select.Option>
                                                        )
                                                    })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Preencha o nome!",
                                                    }
                                                ]}
                                                name="nome"
                                                label="Nome Completo"
                                                style={{ margin: 5 }}
                                            >
                                                <Input
                                                    ref={inputNomeCompletoUserRapido}
                                                    autoComplete="off"
                                                    // onChange={onChangeInputNumberNumero}
                                                    placeholder="Nome"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Preencha o telefone!",
                                                        max: 16
                                                    },
                                                    () => ({
                                                        validator(rule, value) {
                                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                                if (value != '' && value != null) {
                                                                    value = unMask(value);
                                                                    if (value.length > 10) {
                                                                        buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                                            if (valorPromessa) {
                                                                                reject("Celular já existe!");
                                                                            } else {
                                                                                resolve();
                                                                            }
                                                                        }).catch(error => {
                                                                            resolve();
                                                                        })
                                                                    } else {
                                                                        reject("Celular inválido");
                                                                    }
                                                                } else {
                                                                    reject('');
                                                                }
                                                            });
                                                        },
                                                    }),
                                                ]}
                                                name="celular"
                                                label="Telefone"
                                                style={{ margin: 5 }}
                                            >
                                                <Input
                                                    type="tel"
                                                    autoComplete="off"
                                                    onChange={(e) => onChangeInputTelefone(e)}
                                                    placeholder="(44) 9 9999-9999"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                name="cargo_id"
                                                label="Cargo"
                                                initialValue={28}
                                                style={{ margin: 5 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione o cargo!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    {
                                                        dadosDoCargoList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                initialValue={false}
                                                name="enviar_whatsapp"
                                                label="Enviar WhatsApp Cadastro"
                                                style={{ margin: 5 }}
                                                rules={[
                                                ]}
                                            >
                                                <Radio.Group>
                                                    <Radio.Button value={true}>Sim</Radio.Button>
                                                    <Radio.Button value={false}>Não</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        // required: true,
                                                        // message: "Preencha o status!",
                                                    }
                                                ]}
                                                name="isdateproximocontato"
                                                label="Deseja alterar a data do Próximo Contato?"
                                                style={{ margin: 5 }}
                                            >
                                                <>
                                                    <Switch
                                                        checkedChildren="Sim"
                                                        unCheckedChildren="Não"
                                                        checked={isVisibleDateProximoContato}
                                                        onChange={onChangeSwitchIsVisibleDateProximoContato}
                                                    />
                                                </>
                                            </Form.Item>
                                        </Col>
                                        <Col hidden={!isVisibleDateProximoContato} {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Preencha o data!",
                                                    },
                                                    // () => ({
                                                    //     validator(rule, value) {
                                                    //         if (value != '' && value != null) {
                                                    //             if (value.length < 10) {
                                                    //                 return Promise.reject('Data Inválida');
                                                    //             } else {
                                                    //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                                    //                     return Promise.resolve();
                                                    //                 } else {
                                                    //                     return Promise.reject('Data Inválida');
                                                    //                 }
                                                    //             }

                                                    //         } else {
                                                    //             return Promise.resolve();
                                                    //         }
                                                    //     },
                                                    // }),
                                                ]}
                                                name="data_proximo_contato"
                                                label="Próx. Contato"
                                                style={{ margin: 5 }}
                                            >
                                                {/* <Input
                                            autoComplete="off"
                                            placeholder="31/12/2000"
                                            onChange={onChangeDataValidadeTeste}
                                        /> */}
                                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                    <DateTimePicker
                                                        value={selectedDateVincularUserRapido}
                                                        onChange={setSelectedDateVincularUserRapido}
                                                        required={false}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        okLabel="Ok"
                                                        clearLabel="Limpar"
                                                        cancelLabel="Cancelar"
                                                        clearable
                                                        format="dd/MM/yyyy HH:mm"
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            hidden={
                                                stateContratoSituacao?.nome === 'Período de Teste' ||
                                                    stateContratoSituacao?.nome === 'Automático' ?
                                                    false : true
                                            }
                                            {...modalAddModuloInputs}
                                        >
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Preencha o data!",
                                                    },
                                                    // () => ({
                                                    //     validator(rule, value) {
                                                    //         if (value != '' && value != null) {
                                                    //             if (value.length < 10) {
                                                    //                 return Promise.reject('Data Inválida');
                                                    //             } else {
                                                    //                 if (moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
                                                    //                     return Promise.resolve();
                                                    //                 } else {
                                                    //                     return Promise.reject('Data Inválida');
                                                    //                 }
                                                    //             }

                                                    //         } else {
                                                    //             return Promise.resolve();
                                                    //         }
                                                    //     },
                                                    // }),
                                                ]}
                                                name="validade_teste"
                                                // label="Próximo contato"
                                                label="Validade do Teste"
                                                style={{ margin: 5 }}

                                            >
                                                {/* <DatePicker
                                        // showTime
                                        locale={locale}
                                        format="DD/MM/YYYY"
                                    // onChange={onChange}
                                    // onOk={onOk}
                                    /> */}
                                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                    <DatePicker
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        required={false}
                                                        format="dd/MM/yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Form.Item>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                name="poder_id"
                                                label="Poder"
                                                initialValue={2}
                                                style={{ margin: 5 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione o poder!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    loading={loadingPoderSelected}
                                                    notFoundContent={loadingPoderSelected ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    onSelect={(id: any, obj: any) => {

                                                        refIdPoderModalUserRapido.current = id

                                                    }}
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    {dadosPoderList.map((item) => {
                                                        return (
                                                            <Select.Option
                                                                value={item.id}
                                                                key={item.id}
                                                            >
                                                                {item.nome}
                                                            </Select.Option>
                                                        )
                                                    })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Preencha o nome!",
                                                    }
                                                ]}
                                                name="nome"
                                                label="Nome Completo"
                                                style={{ margin: 5 }}
                                            >
                                                <Input
                                                    ref={inputNomeCompletoUserRapido}
                                                    autoComplete="off"
                                                    // onChange={onChangeInputNumberNumero}
                                                    placeholder="Nome"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Preencha o telefone!",
                                                        max: 16
                                                    },
                                                    () => ({
                                                        validator(rule, value) {
                                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                                if (value != '' && value != null) {
                                                                    value = unMask(value);
                                                                    if (value.length > 10) {
                                                                        buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                                            if (valorPromessa) {
                                                                                reject("Celular já existe!");
                                                                            } else {
                                                                                resolve();
                                                                            }
                                                                        }).catch(error => {
                                                                            resolve();
                                                                        })
                                                                    } else {
                                                                        reject("Celular inválido");
                                                                    }
                                                                } else {
                                                                    reject('');
                                                                }
                                                            });
                                                        },
                                                    }),
                                                ]}
                                                name="celular"
                                                label="Telefone"
                                                style={{ margin: 5 }}
                                            >
                                                <Input
                                                    type="tel"
                                                    autoComplete="off"
                                                    onChange={(e) => onChangeInputTelefone(e)}
                                                    placeholder="(44) 9 9999-9999"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                name="cargo_id"
                                                label="Cargo"
                                                initialValue={28}
                                                style={{ margin: 5 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione o cargo!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }}
                                                >
                                                    {
                                                        dadosDoCargoList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                name="cidade_id"
                                                label="Cidade"
                                                style={{ margin: 5 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor selecione a cidade!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    loading={loadingSelectedCidades}
                                                    notFoundContent={loadingSelectedCidades ? <Spin size="small" /> : null}
                                                    optionFilterProp="children"
                                                    onSelect={(id: any, obj: any) => {

                                                        refNomeCidadeModalUserRapido.current = obj?.children

                                                    }}
                                                    placeholder="Selecione..."
                                                    filterOption={(input: any, option: any) => {
                                                        let textDigit = removeAcento(input)
                                                        let listCidade = removeAcento(option?.children);
                                                        return listCidade.indexOf(textDigit) >= 0
                                                    }
                                                    }
                                                >
                                                    {
                                                        dadosCidadesList.map((item) => {
                                                            return (
                                                                <Select.Option
                                                                    value={item.id}
                                                                    key={item.id}
                                                                >
                                                                    {item.nome + ' - ' + item?.microrregiao?.mesorregiao?.estado?.sigla}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                initialValue={false}
                                                name="enviar_whatsapp"
                                                label="Enviar WhatsApp Cadastro"
                                                style={{ margin: 5 }}
                                                rules={[
                                                ]}
                                            >
                                                <Radio.Group>
                                                    <Radio.Button value={true}>Sim</Radio.Button>
                                                    <Radio.Button value={false}>Não</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        // required: true,
                                                        // message: "Preencha o status!",
                                                    }
                                                ]}
                                                name="isdateproximocontato"
                                                label="Deseja alterar a data do Próximo Contato?"
                                                style={{ margin: 5 }}
                                            >
                                                <>
                                                    <Switch
                                                        checkedChildren="Sim"
                                                        unCheckedChildren="Não"
                                                        checked={isVisibleDateProximoContato}
                                                        onChange={onChangeSwitchIsVisibleDateProximoContato}
                                                    />
                                                </>
                                            </Form.Item>
                                        </Col>
                                        <Col hidden={!isVisibleDateProximoContato} {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Preencha o data!",
                                                    },
                                                    // () => ({
                                                    //     validator(rule, value) {
                                                    //         if (value != '' && value != null) {
                                                    //             if (value.length < 10) {
                                                    //                 return Promise.reject('Data Inválida');
                                                    //             } else {
                                                    //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                                    //                     return Promise.resolve();
                                                    //                 } else {
                                                    //                     return Promise.reject('Data Inválida');
                                                    //                 }
                                                    //             }

                                                    //         } else {
                                                    //             return Promise.resolve();
                                                    //         }
                                                    //     },
                                                    // }),
                                                ]}
                                                name="data_proximo_contato"
                                                label="Próx. Contato"
                                                style={{ margin: 5 }}
                                            >
                                                {/* <Input
                                            autoComplete="off"
                                            placeholder="31/12/2000"
                                            onChange={onChangeDataValidadeTeste}
                                        /> */}
                                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                    <DateTimePicker
                                                        value={selectedDateVincularUserRapido}
                                                        onChange={setSelectedDateVincularUserRapido}
                                                        required={false}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        okLabel="Ok"
                                                        clearLabel="Limpar"
                                                        cancelLabel="Cancelar"
                                                        clearable
                                                        format="dd/MM/yyyy HH:mm"
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Form.Item>
                                        </Col>
                                        <Col {...modalAddModuloInputs}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Preencha o data!",
                                                    },
                                                    // () => ({
                                                    //     validator(rule, value) {
                                                    //         if (value != '' && value != null) {
                                                    //             if (value.length < 10) {
                                                    //                 return Promise.reject('Data Inválida');
                                                    //             } else {
                                                    //                 if (moment(value, 'DD/MM/YYYY HH:mm', true).isValid()) {
                                                    //                     return Promise.resolve();
                                                    //                 } else {
                                                    //                     return Promise.reject('Data Inválida');
                                                    //                 }
                                                    //             }

                                                    //         } else {
                                                    //             return Promise.resolve();
                                                    //         }
                                                    //     },
                                                    // }),
                                                ]}
                                                name="validade_teste"
                                                // label="Próximo contato"
                                                label="Validade do Teste"
                                                style={{ margin: 5 }}

                                            >
                                                {/* <DatePicker
                                        // showTime
                                        locale={locale}
                                        format="DD/MM/YYYY"
                                    // onChange={onChange}
                                    // onOk={onOk}
                                    /> */}
                                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                                    <DatePicker
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        required={false}
                                                        format="dd/MM/yyyy"
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Form.Item>
                                        </Col>
                                    </>
                            }
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default ModalUsuarioRapido;
