import moment from 'moment';

/**
 * Conversor de moeda
 */
export const convertMoedaBRParaUS = (valor: string | undefined): number | undefined => {
    if (valor) {
        return parseFloat(valor.replace(/[^0-9,.]/g, '').replace(/[.]/g, '').replace(',', '.'));
    }
    return undefined;
};

/**
 * Conversor de moeda
 */
export const convertMoedaUSParaBR = (valor: any): string | undefined => {
    if (valor) {
        const v = ((valor.replace(/\D/g, '') / 100).toFixed(2) + '').split('.');

        const m: any = v[0].split('').reverse().join('').match(/.{1,3}/g);

        for (let i = 0; i < m.length; i++)
            m[i] = m[i].split('').reverse().join('') + '.';

        const r = m.reverse().join('');

        return r.substring(0, r.lastIndexOf('.')) + ',' + v[1];
    }
    return undefined
};

/**
 * Conversor de moeda
 */
export const convertMoedaUSParaBRInicioSemDigitos = (valor: string): string => {
    if (valor) {
        let tes = parseFloat(valor);
        return tes.toLocaleString('pt-br');
    }
    return ''
};

/**
 * Conversor de moeda
 */
export const convertMoedaUSParaBRInicio = (valor: string): string | undefined => {
    if (valor) {
        let tes = parseFloat(valor);
        return tes.toLocaleString('pt-br', {minimumFractionDigits: 2});
    }
    return undefined
};

/**
 * Conversor de data
 */
export const convertDataBrParaUS = (data: string): string | undefined => {
    if (data) {
        return moment(data, 'DD/MM/YYYY', true).format('YYYY-MM-DD')
    }
    return undefined
};

/**
 * Conversor de data
 */
export const convertDataEHoraBrParaUS = (data: string): string => {
    if (data) {
        return moment(data, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DD HH:mm')
    }
    return ''
};

/**
 * Conversor de data
 */
 export const convertDataUSParaBrasilEHora = (data: null | undefined | string | Date): string => {
    if (data) {
        const dataTDate = new Date(data);
        const newDate = new Intl.DateTimeFormat('pt-BR',
            {
                // timeZone: 'UTC',
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",

            }
        ).format(dataTDate);

        return newDate
    }
    return ''
};

/**
 * Conversor de data
 */
export const convertDataUSParaBR = (data: string): string | undefined => {
    if (data) {
        let d = new Date(data).toLocaleDateString('pt-BR', {timeZone: 'UTC'});
        // console.log(d);
        return d;
        // return moment(data).format('DD/MM/YYYY')
    }
    return undefined
};

/**
 * Conversor de hora timestamp
 */
export const convertHora = (data: string): string | undefined => {
    if (data) {
        // console.log(data);
        return moment(data).format('HH:mm')
        // return moment(data).format('DD/MM/YYYY')
    }
    return undefined
};
